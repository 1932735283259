.DividerBlock {
  // intentionally blank
}

.DividerBlock__hr {
  // override default <hr>
  margin: 0;
  opacity: 1;

  border-width: 1px 0 0;
  border-style: solid;
  border-color: $color-primary;
}
