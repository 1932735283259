@use "variables" as top-links;

.TopLinks__wrapper {
  background-color: $color-black;
}

.TopLinks {
  border-bottom: 1px solid $color-secondary;
  background-color: $color-black;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.TopLinks__link__wrapper {
  flex: 1 1 0;
  position: relative;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      display: block;
      width: 1px;
      background-color: $color-secondary;
    }
  }

  line-height: top-links.$line-height;

  display: flex;
  align-items: center;
  justify-content: center;
}

.TopLinks__link {
  display: block;
  width: 100%;
  padding: top-links.$padding;

  font-size: top-links.$font-size;
  text-align: center;
  text-decoration: none; // override default <a>
  white-space: nowrap;

  &,
  &:hover {
    color: $color-secondary;
  }

  cursor: default;

  @at-root a#{&} {
    cursor: pointer;

    &:hover {
      background-color: rgba($color-secondary, 0.3);
    }
  }
}

.TopLinks__nestedLinks__wrapper {
  position: absolute;
  top: 100%;
  right: -1px;
  left: -1px;

  padding: 1px;
  background-color: $color-secondary;

  opacity: 0;
  pointer-events: none;

  @at-root .TopLinks__link__wrapper:hover #{&} {
    opacity: 1;
    pointer-events: auto;
  }
}

.TopLinks__nestedLinks {
  background-color: $color-black;
}

.TopLinks__nestedLink {
  display: block;
  width: 100%;

  display: block;
  padding: top-links.$padding;

  text-align: center;
  text-decoration: none; // override default <a>

  &,
  &:hover {
    color: $color-secondary;
  }

  &:hover {
    background-color: rgba($color-secondary, 0.3);
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-secondary;
  }
}
