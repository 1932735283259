@use "sass:map";
@use "../../variables" as page-blocks;
@use "./variables" as home-block-hero;

.HomeHeroBlock {
  &:first-child {
    @include media-breakpoint-down(sm) {
      margin-top: ((page-blocks.$gap-xs * -1) - home-block-hero.$offset-xs);
    }

    @include media-breakpoint-up(sm) {
      margin-top: ((page-blocks.$gap * -1) - home-block-hero.$offset);
    }
  }

  display: grid;
}

.HomeHeroBlock__header__wrapper,
.HomeHeroBlock__backgroundImage {
  grid-area: 1 / 1 / 2 / 2;
}

.HomeHeroBlock__header__wrapper {
  position: relative;
  z-index: map.get(home-block-hero.$layers, "header");

  min-height: 540px;
  padding: var(--bs-gutter-x, 0.75rem);

  @include media-breakpoint-down(sm) {
    padding-top: home-block-hero.$offset-xs;
  }

  @include media-breakpoint-up(sm) {
    padding-top: home-block-hero.$offset;
  }

  padding-bottom: home-block-hero.$tiles-overlap;

  &.black {
    color: $color-black;
  }

  &.white {
    color: $color-white;
  }

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.HomeHeroBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.75rem;
  }

  line-height: 1;
}

.HomeHeroBlock__backgroundImage {
  position: relative;
  z-index: map.get(home-block-hero.$layers, "backgroundImage");

  max-height: 640px;
}

.HomeHeroBlock__tiles__wrapper {
  position: relative;
  z-index: map.get(home-block-hero.$layers, "tiles");
  margin-top: (home-block-hero.$tiles-overlap * -1);
}

.HomeHeroBlock__tiles {
  // intentionally blank
}
