@use "sass:map";
@use "../../../variables" as page-blocks;
@use "./variables" as image-title-block;

.ImageTitleBlock__wrapper {
  // intentionally blank
}

.ImageTitleBlock {
  display: grid;
}

.ImageTitleBlock__header__wrapper,
.ImageTitleBlock__backgroundImage {
  grid-area: 1 / 1 / 2 / 2;
}

.ImageTitleBlock__header__wrapper {
  position: relative;
  z-index: map.get(image-title-block.$layers, "header");

  min-height: 540px;
  padding: var(--bs-gutter-x, 0.75rem);

  &.black {
    color: $color-black;
  }

  &.white {
    color: $color-white;
  }

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ImageTitleBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.75rem;
  }

  line-height: 1;
}

.ImageTitleBlock__backgroundImage {
  position: relative;
  z-index: map.get(image-title-block.$layers, "backgroundImage");

  max-height: 640px;
}
