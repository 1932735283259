@use "sass:math";
@use "family.scss/source/src/family" as family;

@import "./components/Tile/Tile";

.Tiles {
  margin: calc(var(--bs-gutter-x, 0.75rem) * -1);

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.Tiles__tile {
  flex: 0 1 auto;

  padding: var(--bs-gutter-x, 0.75rem);

  @include media-breakpoint-down(sm) {
    width: (math.div(11, 12) * 100%);
  }

  @include media-breakpoint-only(sm) {
    width: (math.div(9, 12) * 100%);
  }

  @include family.at-most(3) {
    @include media-breakpoint-up(md) {
      width: (math.div(4, 12) * 100%);
    }
  }

  @include family.at-least(4) {
    @include media-breakpoint-only(md) {
      width: (math.div(6, 12) * 100%);
    }

    @include media-breakpoint-only(lg) {
      width: (math.div(5, 12) * 100%);
    }

    @include media-breakpoint-up(xl) {
      width: (math.div(3, 12) * 100%);
    }
  }
}
