@use "sass:math";

.TextColumnsBlock {
  // intentionally blank
}

.TextColumnsBlock__columns {
  @include media-breakpoint-up(lg) {
    width: (math.div(10, 12) * 100%);
    margin-right: auto;
    margin-left: auto;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
}

.TextColumnsBlock__column {
  &:only-child {
    width: 100%;
  }

  &:not(:only-child) {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      flex: 0 1 auto;
      width: calc(#{math.div(6, 12) * 100%} - 1.5rem);
    }
  }
}

.TextColumnsBlock__column__text {
  max-width: 65ch;
  margin-right: auto;
  margin-left: auto;

  line-height: 2;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {
    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}
