@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");
@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "./global";
@import "../views/Page/Page";
@import "../components/Banners/Announcement/Announcement";
@import "../components/Banners/UrgentBanner/UrgentBanner";
@import "../components/BackgroundImage/BackgroundImage";
@import "../components/Button/Button";
@import "../components/CompanyInfo/CompanyInfo";
@import "../components/Fieldset/Fieldset";
@import "../components/Footer/Footer";
@import "../components/Header/Header";
@import "../components/Input/Input";
@import "../components/InputWrapper/InputWrapper";
@import "../components/LoadingIndicator/LoadingIndicator";
@import "../components/MainMenus/MainMenus";
@import "../components/Modal/Modal";
@import "../components/PageBlocks/PageBlocks";
@import "../components/RadioGroup/RadioGroup";
@import "../components/Select/Select";
@import "../components/Tiles/Tiles";
@import "../components/Textarea/Textarea";
@import "../components/Wizard/Wizard";
@import "../components/CookiePolicy/CookiePolicy";
