@use "../../MainMenus/components/TopLinks/variables" as top-links;

.UrgentBanner {
  display: block;
  width: 100%;
  padding: top-links.$padding;

  border-bottom: 1px solid $color-secondary;
  background-color: $color-urgent;

  @at-root a#{&} {
    text-decoration: none;
  }
  z-index: 3;
  position: absolute;
}

.UrgentBanner__bannerText {
  display: block;

  color: $color-white;
  line-height: top-links.$line-height;
  text-align: center;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {
    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}
