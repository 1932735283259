@use "../Input/variables" as input;

.Textarea__maxLength__wrapper {
  position: relative; // ???
}

.Textarea {
  // override default <Textarea>
  outline: none;

  display: block;
  width: 100%;
  padding: input.$padding;

  color: $color-black;

  border-radius: 3px;
  border: 1px solid $color-gray-200;
  background-color: $color-gray-200;

  &:focus {
    border-color: $color-primary;
    background-color: $color-white;
  }

  &.error {
    border-color: red;
    background-color: $color-white;
  }

  &.maxLength {
    padding-bottom: calc(#{input.$padding * 2} + 0.8rem);
  }
}

.Textarea__maxLength {
  position: absolute;
  bottom: input.$padding;
  left: input.$padding;

  font-size: 80%;
  line-height: 1;

  color: rgba($color-black, 0.5);

  @at-root .Textarea:focus ~ & {
    color: $color-primary;
  }

  pointer-events: none;
}
