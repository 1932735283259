.LoadingIndicator {
  overflow: hidden;

  position: absolute;
  z-index: 10000;

  height: 100%;
  width: 100%;

  background-color: $color-white;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12%;
  padding: 12%;
}

.LoadingIndicator__logo {
  fill: $color-primary;
  height: 3.5rem;
  padding-right: 1rem;
}
