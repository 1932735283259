.ProductBlock {
  .FaqAccordionBlock {
    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .DividerBlock {
    .container {
      max-width: 100%;
      padding: 0;
    }
  }
}

.ProductBlock__accordion__header {
  .accordion-button {
    &:focus,
    &:active,
    &:not(.collapsed) {
      border-color: unset;
      outline: unset;
      box-shadow: unset;
      background: none;
      color: $color-secondary;
    }
  }
}

.ProductBlock__policy__jackets {
  .ProductBlock__policy__link {
    font-size: 1.375rem;
    font-weight: 500;
    padding-left: 2rem;
    &:first-child {
      padding-left: 0;
    }
  }
}

.ProductBlock__accordion {
  .ProductBlock__accordion__item {
    border: none;
    border-bottom: $color-primary 1px solid;
    border-radius: 0;
    &:first-child {
      border-top: $color-primary 1px solid;
    }
    h2 {
      font-size: 2.68rem;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 2rem;
    }
    .product__section {
      padding: 3rem 0;
      h3 {
        font-size: 1.40625rem;
      }
      h4 {
        font-size: 0.875rem;
      }
      @at-root p#{&} {
        margin-bottom: 0; // override default <p>
      }

      > p {
        &:last-child {
          margin-bottom: 0; // override default <p>
        }
      }
    }
  }
}

.ProductBlock__accordion__body {
  padding: 0;
}
