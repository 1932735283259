@use "sass:math";

.Button {
  display: inline-block;
  min-width: 12rem;
  padding: 1rem 1.5rem;

  font-size: 1rem;

  &.isSmall {
    min-width: unset;
    padding: (math.div(2, 3) * 1rem) (math.div(3, 4) * 1rem);
  }

  color: $color-black;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  user-select: none;

  border-radius: 3px;
  border: 1px solid $color-black;
  background-color: $color-secondary;
  box-shadow: 0 0.25rem 1rem 0 rgba($color-black, 0.15);

  &:focus {
    outline: 1px solid $color-primary;
    outline-offset: 0.25rem;
  }

  &:hover {
    color: $color-black;
  }

  &[disabled] {
    cursor: not-allowed;
    color: $color-white;
    border-color: transparent;
    background-color: $color-gray-300;
    box-shadow: none;
  }
  &.Button__Grey {
    background-color: $color-gray-300;
    border: 1px solid #015cab;
  }
}
