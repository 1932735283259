@use "../../../Input/variables" as input;

.RadioOption__wrapper {
  position: relative; // for positioning .RadioOption__fauxOutline

  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  display: inline-flex;
  align-items: flex-start;
  gap: input.$padding;

  &.isOtherOption {
    align-items: center;
  }
}

.RadioOption {
  // intentionally blank
}

.RadioOption__fauxIcon {
  $this: &;

  flex: 0 0 auto;

  display: inline-block;
  width: 1em;
  height: 1em;

  @at-root .RadioOption__wrapper:not(.isOtherOption) > & {
    position: relative;
    top: calc(0.5em + input.$padding + 1px);
    transform: translate3d(0, -50%, 0);
  }

  border-radius: 999px;
  border: 1px solid $color-primary;
  box-shadow: inset 0 0 0 2px $color-white;

  @at-root {
    .RadioOption__wrapper:hover #{$this} {
      background-color: $color-gray-200;
    }

    .RadioOption {
      &:checked ~ #{$this} {
        background-color: $color-primary;
      }
    }
  }
}

.RadioOption__fauxOutline {
  $this: &;

  position: absolute; // relative to .RadioOption__wrapper
  top: 0;
  right: (input.$padding * -1);
  bottom: 0;
  left: (input.$padding * -1);

  border: 1px solid $color-primary;

  pointer-events: none;

  display: none;

  @at-root .RadioOption {
    &:focus ~ #{$this} {
      display: block;
    }
  }
}

.RadioOption__label {
  min-height: input.$height;
  padding-top: input.$padding;
  padding-bottom: input.$padding;

  @at-root .RadioOption__wrapper.isOtherOption > & {
    padding-top: 0;
    padding-bottom: 0;
  }

  display: inline-flex;
  align-items: center;
}
