.HeaderButtonBlock__wrapper {
  text-align: center;
}

.HeaderButtonBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .Button {
    margin-top: 3rem;
  }
}

.HeaderButtonBlock__header,
.HeaderButtonBlock__sub__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.HeaderButtonBlock__header {
  color: $color-secondary;
}
