@use "sass:math";
@use "sass:map";
@use "./variables" as slider-block;

@import "./components/Slide/Slide";

.SliderBlock {
  margin-top: ((3.5rem - math.div(slider-block.$dot-size, 2)) * -1);

  &:not(.isPaginationVisible) {
    margin-bottom: ((3.5rem - math.div(slider-block.$dot-size, 2)) * -1);
  }

  .splide__track {
    position: relative;
    z-index: map.get(slider-block.$layers, "track");

    // compensate for the padding on .SliderBlock__slide
    // that makes room for the box-shadow
    margin-bottom: -1rem;
    margin-right: -1rem;
  }

  .splide__list {
    align-items: flex-start;
  }

  .splide__pagination {
    padding: 0; // override default <ul>

    position: relative;
    z-index: map.get(slider-block.$layers, "pagination");

    @include media-breakpoint-up(lg) {
      transition: width 250ms ease-out;
      width: 50%;

      &.currentSlideHasNoImage {
        width: 100%;
      }
    }

    margin-top: -1rem;
    margin-bottom: (math.div(slider-block.$dot-size, 2) * -1);
  }

  .splide__pagination__page {
    // override default <button>
    padding: 0;
    border: none;
    background: none;

    width: (slider-block.$dot-size * 2);
    height: (slider-block.$dot-size * 2);

    color: $color-white;

    &.is-active {
      color: $color-secondary;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      display: block;
      width: slider-block.$dot-size;
      height: slider-block.$dot-size;

      border-radius: 50%;
      border: 1px solid $color-primary;
      background-color: currentColor;
    }
  }
}

.SliderBlock__slide {
  // height: 100%;

  // prevent box-shadow from bleeding into
  // adjacent slides
  overflow: hidden;

  // make space for the box-shadow. without this,
  // the box-shadow will appear "cut off"
  padding-right: 1rem;
  padding-bottom: 1rem;
}
