.TileBlock__wrapper {
  // intentionally blank
}

.TileBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
