.CookieConsent {
  // bottom: 0px;
  // position: fixed;
  // align-items: baseline;
  // background: $color-black;
  // color: $color-secondary;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // left: 0px;
  // width: 100%;
  // z-index: 999;
  background-color: rgb(11, 28, 35);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100000;
  padding: 10px;

  p,
  h3 {
    color: $color-secondary;
  }

  .CookieConsent__Content,
  .CookieConsent__ButtonWrapper {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    min-height: 32px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 130%;
    padding: 1rem 0;
    color: $color-secondary;
  }

  .CookieConsent__ButtonWrapper {
    text-align: right;
  }

  .CookieConsent__Button {
    display: inline-block;
    padding: 0.5rem;
    min-width: 80px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 0 0.25rem 1rem 0 rgb(11 28 35 / 15%);
    border-radius: 3px;
    border: 2px solid $color-secondary;
  }

  .CookieConsent__AcceptButton {
    background-color: $color-secondary;
    color: $color-black;
    transition: all ease 0.2s;

    &:hover {
      background-color: rgba(149, 218, 234, 0.3);
      color: $color-secondary;
    }
  }

  .CookieConsent__DeclineButton {
    border: 2px solid $color-secondary;
    background: none;
    transition: all ease 0.2s;
    color: $color-secondary;

    &:hover {
      background-color: rgba(149, 218, 234, 0.3);
    }
  }
}

.CookieConsent__Overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}
