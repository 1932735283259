.BackgroundImage {
  // intentionally blank
}

.BackgroundImage__img {
  object-fit: cover;
  object-position: center center;
  display: block;
  width: 100%;
  height: 100%;
}
