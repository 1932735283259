@use "family.scss/source/src/family" as family;
@use "./variables" as tile;

.Tile__wrapper {
  // intentionally blank
}
.Tile__link {
  text-decoration: none;
  color: initial;
  transition: 0.4s ease;
  &:hover {
    color: initial;
    .Tile {
      background-color: $color-secondary;
      transition: 0.4s ease;
    }
  }
}

.Tile {
  height: 100%;

  @include media-breakpoint-down(lg) {
    padding: tile.$padding;
  }

  @include media-breakpoint-up(lg) {
    padding: tile.$padding-lg;
  }

  text-align: center;

  background-color: $color-gray-200;
  box-shadow: 0 0.25rem 1rem 0 rgba($color-black, 0.15);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.Tile__header {
  $this: &;

  margin: 0; // override default <h*>

  @at-root .Tile__wrapper {
    @include family.at-most(3) {
      #{$this} {
        @include media-breakpoint-down(xxl) {
          font-size: 1.875rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 2rem;
        }
      }
    }

    @include family.at-least(4) {
      #{$this} {
        @include media-breakpoint-down(xxl) {
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1.875rem;
        }
      }
    }
  }

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  &::after {
    content: "";
    display: block;
    width: 4rem;
    height: 1px;

    background-color: $color-primary;
  }
}

.Tile__body {
  line-height: 2;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {
    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}

.Tile__image__wrapper {
  order: -1;

  @include media-breakpoint-down(lg) {
    width: calc(100% + (tile.$padding * 2));
    margin-top: (tile.$padding * -1);
    margin-right: (tile.$padding * -1);
    margin-left: (tile.$padding * -1);
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% + (tile.$padding-lg * 2));
    margin-top: (tile.$padding-lg * -1);
    margin-right: (tile.$padding-lg * -1);
    margin-left: (tile.$padding-lg * -1);
  }
}

.Tile__image {
  display: block;
  width: 100%;
}
.Tile__icon {
  height: 32px;
  width: auto;
  margin-top: 1.5rem;
}
