@import "./components/ErrorMessage/ErrorMessage";

.InputWrapper,
.InputWrapper__input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.InputWrapper__input {
  font-size: 1rem;
  padding: 0.7em;
}

.InputWrapper__label {
  display: inline-block;
  line-height: 1.2;
}

.InputWrapper__helperText {
  // override default <p>
  margin-bottom: 0;

  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.2;
}
