@use "sass:math";
@use "../../variables" as main-menus;
@use "../Logo/variables" as logo;
@use "../TopLinks/variables" as top-links;
@use "./variables" as main-links;

.MainLinks__platform {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  overflow-y: scroll;

  @include media-breakpoint-down(sm) {
    padding-top: calc((logo.$padding * 2) + logo.$height-xs);
  }

  @include media-breakpoint-only(sm) {
    padding-top: calc((logo.$padding * 2) + logo.$height);
  }

  @include media-breakpoint-up(md) {
    padding-top: calc(
      (top-links.$padding * 2) + (top-links.$font-size * top-links.$line-height) +
        (logo.$padding * 2) + logo.$height + 1px
    );
  }

  border-top: 1px solid $color-secondary;
  background-color: $color-black;
}

.MainLinks__stage {
  min-height: 100%;

  display: flex;
  flex-direction: column;
}

.MainLinks__wrapper,
.MainLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.MainLinks__wrapper {
  flex: 1 1 auto;

  padding-right: main-links.$gap;
  padding-bottom: main-links.$gap + 1rem;
  padding-left: main-links.$gap;

  gap: main-links.$gap;

  // this forces .MainLinks to center-align
  &::before {
    content: "";
  }
}

.MainLinks {
  @include media-breakpoint-down(sm) {
    gap: main-links.$gap-xs;
  }

  @include media-breakpoint-up(sm) {
    gap: main-links.$gap;
  }
}

.MainLinks__link {
  // override default <button>
  padding: 0;
  border: 0;
  background: none;
  text-align: center;

  &,
  &:hover {
    color: $color-secondary;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }

  line-height: 1;
  text-decoration: none;

  &.hasNestedLinks {
    position: relative; // for positioning __fauxIcon
    padding-right: (main-links.$faux-icon-size * 2);
  }
}

.MainLinks__link__fauxIcon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);

  &,
  &:after {
    display: block;
    width: main-links.$faux-icon-size;
    height: main-links.$faux-icon-size;
  }

  &:after {
    content: "";
    position: absolute; // relative to .MainLinks__link.hasNestedLinks
    top: 0;
    right: 0;

    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: currentColor;

    transform: rotate(45deg) translate3d(-0.0625em, -0.0625em, 0);
  }

  @at-root .MainLinks__link.open & {
    &:after {
      transform: rotate(225deg) translate3d(-0.0625em, -0.0625em, 0);
    }
  }
}

.MainLinks__nestedLinks {
  margin-top: -2rem;
  padding: 1.5rem 0 0 0;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 1rem 2rem;
}

.MainLinks__nestedLink {
  text-align: center;

  &,
  &:hover {
    color: $color-secondary;
  }

  text-decoration: none;
}

.MainLinks__companyInfo {
  font-size: 0.875rem;
  &,
  & a {
    color: $color-primary;
  }
}

.MainLinks__visibilityToggleButton__wrapper {
  position: relative; // for positioning .MainLinks__visibilityToggleButton
}

.MainLinks__visibilityToggleButton {
  // override default <button>
  padding: 0;
  border: 0;
  background: none;

  position: absolute; // relative to .MainLinks__visibilityToggleButton__wrapper
  right: var(--bs-gutter-x, 0.75rem);

  @include media-breakpoint-down(sm) {
    bottom: calc(logo.$padding + math.div(logo.$height-xs, 2));
  }

  @include media-breakpoint-up(sm) {
    bottom: calc(logo.$padding + math.div(logo.$height, 2));
  }

  transform: translate3d(0, 50%, 0);

  &.areMainLinksVisible {
    color: $color-secondary;
  }

  font-size: 0.875rem;
  line-height: 1;
  text-transform: uppercase;

  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.MainLinks__visibilityToggleButton__label {
  // for pixel-perfect alignment with `fauxIcon`
  transform: translate3d(0, 1px, 0);

  // hide the label when the links are visible
  @at-root .MainLinks__visibilityToggleButton.areMainLinksVisible & {
    @extend .visually-hidden;
  }
}

.MainLinks__visibilityToggleButton__fauxIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    border-width: 1px 0;
    border-style: solid;
    border-color: currentColor;

    transition: transform 100ms ease-in-out;
    transform-origin: center center;
  }

  &::before {
    box-shadow: 0 0.5em 0 0 currentColor;
    transform: rotate(0deg) translate3d(0, -0.5em, 0);
  }

  &::after {
    transform: rotate(0deg) translate3d(0, 0.5em, 0);
  }

  @at-root .MainLinks__visibilityToggleButton.areMainLinksVisible & {
    &::before {
      transform: rotate(45deg) translate3d(0, 0, 0);
      box-shadow: 0 0 0 0 currentColor;
    }

    &::after {
      transform: rotate(-45deg) translate3d(0, 0, 0);
    }
  }
}
