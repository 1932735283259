.Modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding-top: var(--bs-gutter-x, 0.75rem);
  padding-bottom: var(--bs-gutter-x, 0.75rem);

  background-color: rgba($color-black, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__panel {
  position: relative; // for positioning .Modal__closeButton

  overflow: auto;
  max-height: calc(100vh - (var(--bs-gutter-x, 0.75rem) * 2));

  @include media-breakpoint-down(sm) {
    padding: 3rem 1.5rem 1.5rem;
  }

  @include media-breakpoint-up(sm) {
    padding: 4rem;
  }

  border-radius: 3px;
  background-color: $color-white;
  box-shadow: 0 0.25rem 1rem 0 rgba($color-black, 0.15);
}

.Modal__closeButton__wrapper {
  position: sticky;
  top: 0;
}

.Modal__closeButton {
  // override default <button>
  padding: 0;
  border: 0;
  background: none;

  position: absolute; // relative to .Modal__closeButton__wrapper

  @include media-breakpoint-down(sm) {
    top: calc(-3rem + var(--bs-gutter-x, 0.75rem));
    right: calc(-1.5rem + var(--bs-gutter-x, 0.75rem));
  }

  @include media-breakpoint-up(sm) {
    top: calc(-4rem + var(--bs-gutter-x, 0.75rem));
    right: calc(-4rem + var(--bs-gutter-x, 0.75rem));
  }

  color: $color-primary;

  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.Modal__closeButton__fauxIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    border-width: 1px 0;
    border-style: solid;
    border-color: currentColor;

    transition: transform 100ms ease-in-out;
    transform-origin: center center;
  }

  &::before {
    transform: rotate(45deg) translate3d(0, 0, 0);
  }

  &::after {
    transform: rotate(-45deg) translate3d(0, 0, 0);
  }
}

.Modal__title {
  // override default <h2>
  margin: 0;

  @include media-breakpoint-down(xxl) {
    font-size: 2rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 2.5rem;
  }

  line-height: 1.2;
}
