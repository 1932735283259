* {
  margin: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $font-family-base;
  font-size: 16px;

  background-color: $body-bg;
}
