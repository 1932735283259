.PolicyFormBlock__wrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.PolicyFormBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.PolicyFormBlock__header {
  text-align: center;
  font-style: normal;
  line-height: 48px;
  letter-spacing: -0.4px;

  @include media-breakpoint-down(xxl) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 2rem;
  }
}

.PolicyFormBlock__sub__header {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  @include media-breakpoint-down(xxl) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.5rem;
  }
}

.PolicyFormBlock__header {
  color: $color-black;
}

.PolicyFormBlock__divider {
  width: 124px;
  height: 1px;
  background: $color-blue;
  display: flex;
  align-items: center;
}

.PolicyFormBlock__divider__wrapper {
  display: flex;
  justify-content: center;
  margin: 4rem 0;
}

.PolicyFormBlock__form__wrapper {
  width: 100%;
  margin: 2rem auto;
  max-width: 300px;

  .InputWrapper__input {
    padding: 0;
  }

  .InputWrapper__input input {
    border: 1px solid #cfd4d9;
    background: $color-white;
  }
}

.PolicyFormBlock__button__wrapper {
  display: flex;
  justify-content: flex-end;
  .Button {
    min-width: 0;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $color-blue;
    color: $color-white;
    font-weight: 400;
    margin: 10px 0px;
  }
}

.PolicyFormBlock__form__wrapper label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  display: flex;
}

.PolicyFormBlock__link {
  color: $color-blue;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  justify-content: flex-start;
  display: flex;
}

.PolicyFormBlock__content__wrapper {
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid #cac4d0;
  background: $color-white;
  line-height: 40px;
  font-weight: 500;

  label {
    line-height: 20px;
    font-size: 14px;
  }
}
