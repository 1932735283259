@use "../Input/variables" as input;

.Fieldset {
  display: flex;
  flex-direction: column;
  gap: (input.$padding * 2);
}

.Fieldset__legend {
  // override default <legend>
  margin: 0;

  color: $color-gray-400;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: input.$padding;

  &::after {
    content: "";
    flex: 1 1 auto;
    display: block;
    height: 1px;
    background-color: $color-gray-300;
  }
}
