@use "sass:math";

.ContactFormBlock__wrapper {
  // intentionally blank
}

.ContactFormBlock {
  @include media-breakpoint-up(lg) {
    width: (math.div(10, 12) * 100%);
    margin-right: auto;
    margin-left: auto;
  }

  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.ContactFormBlock__header__wrapper {
  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;
}

.ContactFormBlock__header {
  margin: 0; // override default <h*>
}

.ContactFormBlock__form {
  .InputWrapper {
    padding-bottom: 2rem;
  }
}
