@use "sass:math";

.AgentFormBlock__wrapper {
  // intentionally blank
}

.AgentFormBlock {
  @include media-breakpoint-up(lg) {
    width: (math.div(10, 12) * 100%);
    margin-right: auto;
    margin-left: auto;
  }

  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.AgentFormBlock__header__wrapper {
  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;
}

.AgentFormBlock__header {
  margin: 0; // override default <h*>
  h1 {
    text-align: center;
  }
}

.AgentFormBlock__ButtonWrapper {
  display: flex;
  justify-content: center;
}

.AgentFormBlock__form {
  .InputWrapper {
    padding-bottom: 2rem;
  }
}

.AgentFormBlock__columns {
  @include media-breakpoint-up(lg) {
    width: (math.div(10, 12) * 100%);
    margin-right: auto;
    margin-left: auto;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
}

.AgentFormBlock__column {
  &:only-child {
    width: 100%;
  }

  &:not(:only-child) {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      flex: 0 1 auto;
      width: calc(#{math.div(6, 12) * 100%} - 1.5rem);
    }
  }
}

.AgentFormBlock__column__text {
  max-width: 65ch;
  margin-right: auto;
  margin-left: auto;

  line-height: 2;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {
    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}
