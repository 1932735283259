.ButtonBlock {
  margin-bottom: 3rem;
}

.ButtonBlock__title {
  margin-bottom: 0.5rem;
}

.ButtonBlock__button__wrapper {
  width: 100%;
  text-align: center;
  .ButtonBlock__button {
    width: 100%;
    a {
      border: 1px solid $color-blue;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px;
      text-decoration: none;
      width: 100%;
      display: flex;
      color: $color-blue;
    }
    a:hover {
      background: $color-blue;
      color: $color-white;
    }
  }

  @include media-breakpoint-up(sm) {
    display: flex;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.ButtonBlock__group__wrapper {
  width: 100%;
  text-align: center;
  margin: 0.5rem 5px;

  .ButtonBlock__image {
    display: flex;
    margin: 0.5rem 0;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}

.ButtonBlock__condition {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0;
}
